import type { NextPage } from "next";
import Head from "next/head";
import { DraftHome } from "./home";

const Home: NextPage = () => {
  return (
    <>
      <Head>
        <title>Marval Home Page</title>
        <meta name="description" content="Generated by create next app" />
      </Head>
      <DraftHome />
    </>
  );
};

export default Home;
